.Login {
  --background-color: #282c34;
  --color: white;
}

.Login {
  background-color: var(--background-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--color);
}
