.Filters {
  padding: 8px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 4px;
}
.Filters h3{
  font-size: 1em;
}
.Filters .Latest {
  align-self: center;
}
.Filters .Latest label {
  color: hsl(0, 0%, 50%);
}
.Filters .Latest input {
  margin-left: 8px;
}

.Filters .Filter {
  grid-column-end: span 3;
}
.Filters .Filter input {
  width: 100%;
  height: 30px;
  border-radius: 4px;
  border-width: 1px;
  padding-left: 8px;
  font-size: 16px;
}

@media screen and (max-width: 800px) {
  .Filters {
    grid-template-columns: repeat(2, 1fr);
  }
  .Filters .Filter { display: none; }
}
