.UserInfo {
  grid-area: user;
  justify-self: right;
  display: grid;
  grid: 1fr / 1fr auto;
  align-items: center;
  color: var(--header-color);
}

.UserInfo-loading { color: #999; }
.UserInfo-error { color: #C00; }

.UserInfo img {
  width: 64px;
  padding: 8px;
  border-radius: 64px;
}
@media screen and (max-width: 800px) {
  .UserInfo span { display: none; }
}
