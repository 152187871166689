.Header {
  grid-area: header;
  background-color: var(--header-background);
  display: grid;
  grid: "logo title search settings user" auto
        / auto auto 1fr auto auto;
  align-items: center;
  grid-column-gap: 10px;
}

.Header-logo {
  grid-area: logo;
  height: 80px;
}
.Header-logo img {
  min-width: 64px;
  height: 64px;
  padding: 8px;
}
.Header-title {
  grid-area: title;
  color: var(--header-color);
  font-size: 1.5em;
}
.Header-search {
  grid-area: search;
  max-width: 600px;
}

@media screen and (max-width: 800px) {
  .Header {
    grid: "logo title  user" auto
          "logo search user" auto
          / auto 1fr auto;
    grid-column-gap: 2px;
  }
  .Header-title {
    margin: 0;
    justify-self: center;
  }
  .Header-search {
    height: 20px;
    margin: 6px 0;
  }
}
