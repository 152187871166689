.App {
  --controls-background: #EEE;
  --controls-button-background: rgb(240,240,240);
  --controls-button-hover-background: rgba(0,0,0,0.2);
  --controls-button-hover-disabled: rgba(0,0,0,0.1);
}
.App[data-theme='dark'] {
  --controls-background: rgba(255,255,255,12%);
  --controls-button-background: rgba(240,240,240, 12%);
}
.App[data-theme='dark'] button {
  background: var(--controls-background);
  border: 1px transparent;
}
.App[data-theme='dark'] svg path {
  fill: var(--text-color);
}

.Controls {
  display: grid;
  grid: "progress progress progress progress" auto
        "cover    buttons time title" auto
        "cover    buttons time album"  auto
        "cover    buttons time artist"  auto
        / auto auto 1fr 10fr;
  background-color: var(--controls-background);
  align-items: center;
  grid-column-gap: 16px;
  padding-bottom: 4px;
}
.App[data-layout='left'] .Controls,
.App[data-layout='right'] .Controls {
  grid: "cover cover" auto
        "progress progress" auto
        "buttons time" auto
        "title title"  auto
        "album album"  auto
        "artist artist" 1fr
        / auto 1fr;
  grid-column-gap: 0;
  width: 300px;
}
.App[data-layout='left'] .Controls img,
.App[data-layout='right'] .Controls img {
  width: 300px;
  height: 300px;
}
.App[data-layout='left'] .Controls-artist,
.App[data-layout='right'] .Controls-artist {
  align-self: start;
}

.App[data-layout='left'] .Controls > div,
.App[data-layout='right'] .Controls > div {
  padding: 4px 8px;
}

.Controls-progress {
  grid-area: progress;
}
.Controls progress {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 8.4px;
  pointer-events: none;
  box-sizing: border-box;
  border: 0;
  display: block;
  background-color: var(--controls-background);
}
.Controls progress::-webkit-progress-bar {
  background-color: var(--controls-background);
}

.Controls progress::-webkit-progress-value {
  background: var(--accent);
  border-radius: 1px;
}

.Controls progress::-moz-progress-bar {
  background: var(--accent);
  border-radius: 2px;
}
.Controls-time  {
  grid-area: time;
  justify-self: center;
}

.Controls img {
  grid-area: cover;
  width: 64px;
}
.Controls-artist { grid-area: artist; }
.Controls-album { grid-area: album; }
.Controls-title { grid-area: title; }
.Controls-error { grid-area: title; }
.Controls-buttons {
  grid-area: buttons;
  display: grid;
  grid-template-columns: repeat(4, auto);
  align-items: center;
  grid-column-gap: 8px;
  justify-self: left;
}
.Controls-buttons button {
  height: 32px;
  width: 32px;
  padding: 0;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  background: var(--button-background);
}
.Controls-buttons button:hover {
  background: var(--controls-button-hover-background);
}
.Controls-buttons button:disabled {
  background: var(--controls-button-hover-disabled);
  cursor: initial;
}
.Controls-buttons button:nth-child(2) {
  height: 48px;
  width: 48px;
}

@media screen and (max-width: 800px) {
  .App[data-layout] .Controls {
    grid: "progress progress progress" auto
          "cover    buttons time" auto
          "cover    title artist" auto
          "album    album album" auto
          / auto auto 1fr;
    grid-column-gap: 4px;
    width: initial;
  }
  .App[data-layout] .Controls-buttons {
    grid-column-gap: 10px;
  }
  .App[data-layout] .Controls progress {
    height: 4px;
  }
  .App[data-layout] .Controls-album {
    padding: 0 8px;
  }
  .App[data-layout] .Controls-artist {
    min-width: 25vw;
    padding: 0 8px;
    text-align: right;
  }
  .App[data-layout] .Controls-time {
    justify-self: right;
    padding-right: 8px;
  }
  .App[data-layout] .Controls img {
    width: 64px;
    height: 64px;
  }
  .App[data-layout] .Controls > div {
    padding: 0;
  }
}
