.Album{
  display: grid;
  grid: "cover year    title     actions" auto
        "cover country publisher actions" auto
        "cover tracks  genre     actions" auto
        / auto auto 1fr auto;
  grid-column-gap: 8px;
  padding-bottom: 10px;
}
.Album img {
  grid-area: cover;
  width: 64px;
  height: 64px;
}
.Album-year { grid-area: year; }
.Album-country { grid-area: country; }
.Album-tracks { grid-area: tracks; }
.Album-title { grid-area: title; }
.Album-publisher { grid-area: publisher; }
.Album-genre { grid-area: genre; }
.Album-actions { grid-area: actions; }
.Album-actions button {
  padding: 0;
  width: 24px;
  height: 24px;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  background: var(--button-background);
}
.Album-actions button:hover {
  background: var(--controls-button-hover-background);
}
