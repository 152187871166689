.Queue button {
  padding: 0;
  width: 24px;
  height: 24px;
  vertical-align: bottom;
}

.Queue-active {
  background: var(--accent);
}
.Queue button {
  padding: 0;
  width: 24px;
  height: 24px;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  background: var(--button-background);
}
.Queue button:hover {
  background: var(--controls-button-hover-background);
}
