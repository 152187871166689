.AlbumPage table button {
  padding: 0;
  width: 24px;
  height: 24px;
  vertical-align: bottom;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  background: var(--button-background);
}
.AlbumPage table button:hover {
  background: var(--controls-button-hover-background);
}

@media screen and (max-width: 800px) {
  .AlbumPage h1 { margin: 5px 0; }
  .AlbumPage h2 { margin: 2px 0; }
  .AlbumPage h3 { margin: 1px 0; }
}
