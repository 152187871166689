button svg {
  height: 100%;
  width: 100%;
}

.App {
  --accent: #388E3C;
  --header-background: #000;
  --header-color: white;
  --text-color: black;
}

.App[data-theme='dark'] {
  --header-background: #000;
  --accent: #A5D6A7;
  --text-color: rgba(255, 255, 255, 87%);
  --background: #121212;
}

.App {
  height: 100vh;
  display: grid;
  grid: "header header" auto
        "browser queue" 1fr
        "footer footer" auto
        / 4fr 5fr;
  background: var(--background);
  color: var(--text-color);
}

.App[data-layout='left'] {
  grid: "header header header" auto
        "footer browser queue" 1fr
        / auto 4fr 4fr;
}

.App[data-layout='right'] {
  grid: "header header header" auto
        "browser queue footer" 1fr
        / 4fr 4fr auto;
}

.App-intro {
  font-size: large;
}
.App-panels { display: none; }

.App main {
  grid-area: browser;
  overflow: auto;
  padding: 0 16px;
}
.App aside {
  grid-area: queue;
  overflow: auto;
}
.App footer {
  grid-area: footer;
}

@media screen and (max-width: 800px) {
  .App[data-layout] {
    grid: "header" auto
          "panels" auto
          "main"   1fr
          "footer" auto
          / 1fr;
  }
  .App main {
    grid-area: main;
    padding: 0 8px;
  }
  .App-panels {
    grid-area: panels;
    display: grid;
    grid: auto / 1fr 1fr;
    text-align: center;
    margin-bottom: 4px;
  }
  .App-panels input { display: none; }

  .App-panels label {
    background-color: var(--controls-background);
  }
  .App-panels #panel-main:checked ~ label[for='panel-main'],
  .App-panels #panel-side:checked ~ label[for='panel-side'] {
    background-color: var(--background);
  }
  .App aside { grid-area: main; }
  .App[data-panel='side'] main { display: none; }
  .App[data-panel='main'] aside { display: none; }

  .ArtistName { margin: 0; }
}
