.Settings {
  grid-area: settings;
  justify-self: right;

  display: grid;
  grid: auto / auto auto;
  grid-column-gap: 10px;
}

.Settings-theme {
  width: 100px;
}

.Settings-layout {
  width: 170px;
}

@media screen and (max-width: 800px) {
  .Settings { display: none; }
}
